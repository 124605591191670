<template>
  <v-container fluid>
    <v-row justify="start" class="ma-2" no-gutters>

      <v-col cols="12" lg="4">
        <HelloDashboard />
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
  import utils from "@/mixins/utils";

export default {

  name: "Dashboard",

  components: {
    HelloDashboard: () => import("@/components/Dashboard/Hello")
  },

  mixins: [utils],

  data() {
    return {

    }
  },

  created()
  {
    this.setHead(this.$t('dashboard.titles.pageTitle'));
  },
}
</script>
