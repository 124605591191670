export default
{
  methods:
  {
    setHead(title, description = "")
    {
      if (title)
        this.$store.commit("pageHead/setTitle", title);
      else
        this.$store.commit("pageHead/setTitle", null);
    },
  }
}